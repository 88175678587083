<template>
    <header class="header" :class="{ transparent: transparent }">
        <section class="wrapper">
            <div class="logo">
                <a href="http://chinzsoft.com/" title="钦泽软件">
                    <img src="../assets/img/logo.png" alt="山东钦泽软件有限公司" />
                </a>
            </div>
            <nav class="nav">
                <ul>
                    <li v-for="(item, index) in navList" :key="index" :class="{ active: index == navActivatedIndex }">
                        <button @click="navigate(item, index)" class="btn">{{ item.meta.zhName }}</button>
                        <section class="sub" v-if="item.children">
                            <ul>
                                <li v-for="(item, index) in item.children" :key="index">
                                    <button @click="navigate(item, index)" class="btn">{{ item.meta.zhName }}</button>
                                </li>
                            </ul>
                        </section>
                    </li>
                </ul>
                <!-- <div class="attr-nav">
                    <button class="btn-search">
                        <i class="fa fa-search"></i>
                    </button>
                    <button class="btn-menu" @click="toggleNav">
                        <i class="fa fa-bars"></i>
                    </button>
                </div> -->
            </nav>
        </section>
        <transition name="fade">
            <aside class="aside" v-if="aside">
                <h4>关于钦泽</h4>
                <i class="fa fa-times close-btn" @click="toggleNav"></i>
                <p>
                    专业从事网络多媒体产品及嵌入式软件研发的高新技术公司。根据医疗、政务、金融、商场等行业特性以及业务需求，同时结合自身产品的特点与多年的行业经验自主研发出多种互联网+智慧医疗解决方案
                </p>
                <h5>解决方案</h5>
                <ul>
                    <li :class="{ active: index == navActivatedIndex }" :key="index" v-for="(item, index) in navList">
                        <button @click="navigate(item, index)">
                            {{ item.meta.zhName }}
                        </button>
                    </li>
                </ul>
                <h4>联系我们</h4>
                <h6>
                    <i class="fa fa-phone"></i>
                    0531-85918895
                </h6>
            </aside>
        </transition>
    </header>
</template>
<script>
    import { mapState } from 'vuex'
    export default {
        name: 'HeaderBarComponent',
        data() {
            return {
                aside: false, // 侧边栏
                medicalList: false,
                page: null,
            }
        },
        computed: {
            navList() {
                return this.$router.options.routes
            },
            ...mapState({
                navActivatedIndex: state => state.navActivatedIndex,
            }),
        },
        props: {
            transparent: {
                require: true,
                type: Boolean,
            },
        },
        methods: {
            navigate(item, index) {
                this.$store.commit('changeNavActivatedIndex', index)
                this.aside = false
                if (item.path != this.$route.path) {
                    this.$router.push(item.path)
                }
                this.page = index
            },
            toggleNav() {
                this.aside = !this.aside
            },
        },
    }
</script>
<style lang="less" scoped>
    @import url('../assets/css/style.less');
    .header {
        height: 90px;
        z-index: 100;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        background: #fff;
        box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);

        &.transparent {
            background: none;
            border-bottom: none;
            box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.06);
        }

        .wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 1170px;
            margin: auto;
        }

        .logo {
            display: inline-block;
            img {
                height: 60px;
            }
        }

        .nav {
            display: flex;

            > ul {
                display: flex;
                height: 90px;

                > li {
                    display: flex;
                    align-items: center;
                    position: relative;
                    height: 90px;
                    padding: 0 15px;

                    &.active,
                    &:hover {
                        > button {
                            color: #014a96;
                        }
                    }

                    &:hover {
                        .sub {
                            display: block;
                        }
                    }

                    > button {
                        font-size: 18px;
                        .transition;
                    }

                    .sub {
                        display: none;
                        position: absolute;
                        top: 70px;
                        .hc;
                        background: #fff;
                        padding: 5px 15px;
                        width: 164px;
                        border-radius: 3px;
                        box-shadow: 0 2px 10px 0 rgb(0 0 0 / 25%);
                        z-index: 10;
                        text-align: center;

                        li {
                            margin: 5px 0;
                            padding: 12px 15px;
                            border-radius: 8px;
                            .transition;

                            &.active,
                            &:hover {
                                color: #fff;
                                background: #014a96;
                            }

                            button {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }

            .attr-nav {
                display: flex;
                align-items: center;

                .btn-search {
                    position: relative;
                    padding: 0 10px;
                    width: 36px;
                    height: 42px;
                    i {
                        font-size: 18px;
                    }

                    &::before {
                        position: absolute;
                        left: -3px;
                        top: -10px;
                        content: '';
                        height: 30px;
                        width: 1px;
                        background: #e7e7e7 none repeat scroll 0 0;
                        margin-top: 15px;
                    }
                }
                .btn-menu {
                    width: 36px;
                    height: 42px;

                    padding: 0 10px;
                    i {
                        font-size: 18px;
                    }
                }
            }
        }
    }

    .aside {
        position: fixed;
        top: 0;
        right: 0;
        width: 400px;
        background: #fff;
        padding: 50px;
        height: 937px;

        h4 {
            display: block;
            font-weight: 600;
            margin-bottom: 25px;
            padding-bottom: 15px;
            position: relative;
            text-transform: uppercase;
            font-size: 18px;
            z-index: 1;

            &::after {
                background: #02acbb none repeat scroll 0 0;
                bottom: -2px;
                content: '';
                height: 2px;
                left: 0;
                position: absolute;
                width: 50px;
            }
        }

        .close-btn {
            position: absolute;
            top: 29px;
            right: 30px;
            cursor: pointer;
        }

        p {
            color: #666;
            line-height: 26px;
            margin: 0 0 15px;

            margin-bottom: 46px;
        }
        h5 {
            display: block;
            font-weight: 600;
            margin-bottom: 25px;
            padding-bottom: 15px;
            position: relative;
            text-transform: uppercase;
            font-size: 18px;
            z-index: 1;
            &::after {
                background: #02acbb none repeat scroll 0 0;
                bottom: -2px;
                content: '';
                height: 2px;
                left: 0;
                position: absolute;
                width: 50px;
            }
        }
        ul {
            margin-bottom: 40px;

            li {
                margin-left: 66px;
                margin-bottom: 11px;

                button {
                    display: block;
                    padding: 0.3rem 0;
                    font-weight: bold;
                }
            }
        }
    }
</style>
