<template>
    <main id="app">
        <HeaderBarComponent :transparent="transparent" />
        <transition name="fade" mode="out-in">
            <router-view />
        </transition>
        <FooterBarComponent v-if="!transparent" />
    </main>
</template>
<script>
    import HeaderBarComponent from './components/HeaderBarComponent.vue'
    import FooterBarComponent from './components/FooterBarComponent.vue'
    export default {
        data() {
            return {
                transparent: true,
            }
        },
        components: {
            HeaderBarComponent,
            FooterBarComponent,
        },
        watch: {
            $route(val) {
                if (val.name.includes('Home')) {
                    this.transparent = true
                } else {
                    this.transparent = false
                }
            },
        },
    }
</script>
