import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 第三方插件
import 'animate.css'
//公共组件
import VueAwesomeSwiper from 'vue-awesome-swiper'
import BannerComponent from './components/BannerComponent.vue'

//引入样式
import 'swiper/css/swiper.css'
import './assets/css/font-awesome.min.css'
import './assets/css/base.less'
import './assets/css/iconfont.css'

if (document.body.clientWidth < 750) {
    location.href = 'http://m.chinzsoft.com/#/'
}

Vue.use(VueAwesomeSwiper)
Vue.component('BannerComponent', BannerComponent)

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
