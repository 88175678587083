<template>
    <footer class="footer">
        <section class="top-wrapper">
            <div class="about">
                <h2>关于钦泽</h2>
                <ul class="info">
                    <li :key="index" v-for="(item, index) in infoList">
                        <div class="left">
                            <i :class="item.icon"></i>
                        </div>
                        <div class="right">
                            <div class="title">{{ item.title }}</div>
                            <div class="content">{{ item.content }}</div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="plans">
                <div class="equal-height">
                    <h2>解决方案</h2>
                    <ul class="link">
                        <li :key="index" v-for="(item, index) in navList">
                            <button @click="navigate(item, index)">
                                <i class="fa fa-arrow-right c"></i>
                                <span>{{ item.meta.zhName }}</span>
                            </button>
                        </li>
                    </ul>
                </div>

                <div class="qr">
                    <img src="../assets/img/qr.jpg" alt="二维码" />
                    <p>扫码关注钦泽公众号</p>
                </div>
            </div>
        </section>
        <section class="bottom-wrapper">
            <p>山东钦泽软件有限公司</p>
            <p>Copyright © 2016-2019 chinzsoft.com. All rights reserved 备案号：鲁ICP备19010877号-1</p>
        </section>
    </footer>
</template>
<script>
    export default {
        name: 'FooterBarComponent',
        data() {
            return {
                infoList: [
                    {
                        icon: 'fa fa-phone',
                        title: '电话',
                        content: '0531-8591-8895；    秦经理: 13220556572',
                    },
                    {
                        icon: 'fa fa-envelope',
                        title: '邮箱',
                        content: 'marketing@chinzsoft.com',
                    },
                    {
                        icon: 'fa fa-map',
                        title: '地址',
                        content: '济南市天桥区K88名泉广场E3-512',
                    },
                ],
            }
        },
        computed: {
            navList() {
                return this.$router.options.routes
            },
        },
        methods: {
            navigate(item, index) {
                this.$store.commit('changeNavActivatedIndex', index)
                this.$router.push(item.path)
            },
        },
    }
</script>
<style lang="less" scoped>
    @import url('../assets/css/style.less');
    .footer {
        background: #1d2024;
        color: #ccc;
        .top-wrapper {
            display: flex;
            justify-content: space-between;
            padding: 60px 0;
            width: 1170px;
            margin: auto;

            h2 {
                position: relative;
                color: #fff;
                font-weight: bold;
                font-size: 24px;

                &::before {
                    position: absolute;
                    left: 33px;
                    bottom: -21px;
                    content: '';
                    height: 2px;
                    width: 5px;
                    background: #014a96;
                }

                &::after {
                    position: absolute;
                    left: 0;
                    bottom: -21px;
                    content: '';
                    height: 2px;
                    width: 30px;
                    background: #014a96;
                }
            }

            .about {
                width: 585px;

                .info {
                    margin-top: 50px;
                    padding-left: 20px;

                    li {
                        display: flex;
                        padding-bottom: 15px;
                        margin-bottom: 15px;
                        border-bottom: 1px solid rgba(255, 255, 255, 0.1);

                        &:last-child {
                            border-bottom: none;
                        }

                        .left {
                            margin-right: 20px;
                            .fa {
                                display: inline-block;
                                font-size: 30px;
                                color: #04427d;
                            }
                        }

                        .right {
                            div {
                                color: #fff;
                                font-size: 18px;
                            }
                            .content {
                                margin-top: 16px;
                            }
                        }
                    }
                }
            }

            .plans {
                display: flex;
                position: relative;
                width: 585px;
                margin-left: 68px;

                .qr {
                    position: absolute;
                    bottom: 0;
                    right: 0;

                    img {
                        display: block;
                        margin: auto;
                        width: 110px;
                        height: 110px;
                        border: 5px solid #ffffff;
                    }

                    p {
                        margin-top: 22px;
                    }
                }

                .link {
                    margin-top: 50px;
                    padding-left: 20px;
                    li {
                        margin-top: 20px;
                        button {
                            font-size: 18px;
                            .transition;
                            i {
                                margin-right: 15px;
                            }
                            &:hover {
                                color: #014a96;
                            }
                        }
                    }
                }
            }
        }
        .bottom-wrapper {
            border-top: 1px solid rgba(255, 255, 255, 0.2);
            padding: 25px 0;
            p {
                text-align: center;
                line-height: 2;
                letter-spacing: normal;
            }
        }
    }
</style>
