<template>
    <section class="banner">
        <img :src="img" alt="banner" />
    </section>
</template>
<script>
    export default {
        name: 'BannerComponent',
        props: {
            img: {
                type: String,
            },
        },
    }
</script>
<style lang="less" scoped>
    @import url('../assets/css/style.less');
    .banner {
        margin-top: 90px;
        img {
            height: 396px;
            width: 100%;
        }
    }
</style>
